import { useCallback, useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import IdCell from 'layouts/ecommerce/orders/order-list/components/IdCell';
import DefaultCell from 'layouts/ecommerce/orders/order-list/components/DefaultCell';
import Typography from '@mui/material/Typography';
import { CSVLink } from 'react-csv';
import {
  useAuth,
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from 'reactfire';
import {
  collection,
  doc,
  updateDoc,
  orderBy,
  query,
  where,
  documentId,
  QueryConstraint,
  onSnapshot,
} from 'firebase/firestore';
import SubscriptionPlanCell from './components/SubscriptionPlanCell';
import { usePortalUser } from 'routes';
import CheckboxCell from './components/CheckboxCell';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Link,
} from '@mui/material';
import { PortalUser } from './EditProvider';
import { ServiceProvider } from 'firebaseModels';
import MDInput from 'components/MDInput';
import { useNavigate } from 'react-router-dom';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { httpsCallable } from 'firebase/functions';
import { s } from '@fullcalendar/core/internal-common';
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Done,
} from '@mui/icons-material';
import MDTypography from 'components/MDTypography';

type LicenceStat = {
  app_opened: number;
  days_used: number;
  updated: string;
};

type licenceStats = {
  [key: string]: LicenceStat;
};

function LicenceList(): JSX.Element {
  const navigate = useNavigate();
  // const [menu, setMenu] = useState(null);
  const { currentUser } = useAuth();
  const email = currentUser?.providerData[0]?.email || '';
  const { portalUser } = usePortalUser(email);
  const isSuperAdmin =
    portalUser && (portalUser as PortalUser).level === 'superadmin';

  const isReseller =
    portalUser && (portalUser as PortalUser).level === 'reseller';

  const [licences, setLicences] = useState<any>([]);
  const [licenceStatus, setLicenceStatus] = useState<string>('loading');
  const [licenceStats, setLicenceStats] = useState<licenceStats>({});
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(
    isSuperAdmin || isReseller ? null : portalUser?.provider.id
  );
  const functions = useFunctions();
  const getlicencestats = useMemo(
    () => httpsCallable(functions, 'getlicencestats'),
    [functions]
  );

  const firestore = useFirestore();
  const licenceCollection = useMemo(
    () => collection(firestore, 'Licences'),
    [firestore]
  );

  // get reference to selected provider
  const providerRef = useMemo(
    () =>
      selectedProviderId
        ? doc(firestore, 'ServiceProvider', selectedProviderId)
        : null,
    [firestore, selectedProviderId]
  );

  const constraints: QueryConstraint = useMemo(() => {
    let constraints =
      isSuperAdmin || isReseller
        ? providerRef
          ? where('provider', '==', providerRef)
          : undefined
        : where('provider', '==', portalUser?.provider || false);
    if (!portalUser) {
      constraints = where('provider', '==', false);
    }
    return constraints;
  }, [isSuperAdmin, isReseller, providerRef, portalUser]);

  const licenceQuery = useMemo(
    () => query(licenceCollection, constraints, orderBy('created', 'desc')),
    [constraints, licenceCollection]
  );

  useEffect(() => {
    // follow snapshot of licence collection with given constraints
    setLicenceStatus('loading');
    const unsubscribe = onSnapshot(
      licenceQuery,
      (snapshot) => {
        setLicences(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
        setLicenceStatus('success');
      },
      (error) => {
        console.error(error);
        setLicenceStatus('error');
      }
    );

    return () => unsubscribe();
  }, [licenceQuery]);

  // console.log('licenceData', licenceData);
  const subscriptionPlansCollection = useMemo(
    () => collection(firestore, 'SubscriptionPlan'),
    [firestore]
  );

  const subscriptionPlansQuery = useMemo(
    () => query(subscriptionPlansCollection, orderBy('name', 'asc')),
    [subscriptionPlansCollection]
  );

  const { status: subscriptionPlansStatus, data: subscriptionPlans } =
    useFirestoreCollectionData(subscriptionPlansQuery, {
      idField: 'id', // this field will be added to the object created from each document
    });

  // get service providers
  const serviceProviderCollection = useMemo(
    () => collection(firestore, 'ServiceProvider'),
    [firestore]
  );

  const serviceProviderConstraints = useMemo(
    () =>
      !isSuperAdmin && !isReseller
        ? where(documentId(), '==', portalUser?.provider.id || 'none')
        : undefined,
    [isReseller, isSuperAdmin, portalUser?.provider.id]
  );

  const serviceProviderQuery = useMemo(
    () =>
      query(
        serviceProviderCollection,
        serviceProviderConstraints,
        orderBy('name', 'asc')
      ),
    [serviceProviderCollection, serviceProviderConstraints]
  );

  // console.log('serviceProviderQuery', serviceProviderQuery);
  // console.log('portalUser', portalUser);

  const { status: serviceProviderStatus, data: serviceProviders } =
    useFirestoreCollectionData(serviceProviderQuery, {
      idField: 'id', // this field will be added to the object created from each document
    });

  // console.log('serviceProviders', serviceProviders);

  useEffect(() => {
    if (
      !(isSuperAdmin || isReseller) &&
      portalUser &&
      serviceProviders &&
      serviceProviders.length > 0 &&
      !selectedProviderId
    ) {
      setSelectedProviderId(portalUser?.provider.id);
    }
  }, [
    isReseller,
    isSuperAdmin,
    portalUser,
    selectedProviderId,
    serviceProviders,
  ]);

  // console.log(serviceProviders);
  // console.log('licences', licences);
  // const openMenu = (event: any) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);

  const dataTableData = useMemo(() => {
    // console.log('dataTableData licences', licences);
    // console.log('dataTableData serviceProviders', serviceProviders);
    // console.log('dataTableData portalUser', portalUser);
    // console.log('dataTableData selectedProviderId', selectedProviderId);
    // console.log('dataTableData isSuperAdmin', isSuperAdmin);
    // console.log('dataTableData isReseller', isReseller);

    let columns: any = [
      {
        Header: 'created',
        accessor: 'created',
        type: 'datetime',
        sortType: 'datetime',
        Cell: ({ value }: any) => {
          return <DefaultCell value={value?.toLocaleDateString() || ''} />;
        },
      },
    ];
    if ((isSuperAdmin || isReseller) && !selectedProviderId) {
      columns = columns.concat([
        {
          Header: 'provider',
          accessor: 'provider',
          Cell: ({ value }: any) => {
            return <DefaultCell value={value} />;
          },
        },
      ]);
    }
    columns = columns.concat([
      {
        Header: 'Code',
        accessor: 'licenceCode',
        Cell: ({ value }: any) => <IdCell id={value} />,
      },
      {
        Header: 'description',
        accessor: 'description',
        Cell: ({ value }: any) => {
          return <DefaultCell value={value} />;
        },
      },
      {
        Header: 'plan',
        accessor: 'plan',
        Cell: ({ row, value }: any) => {
          return <SubscriptionPlanCell reference={value} />;
        },
      },
      {
        Header: 'has demo',
        accessor: 'isDemo',
        sortType: 'boolean',
        Cell: ({ value }: any) => {
          const [id, checked] = value;
          return (
            <CheckboxCell
              onChange={async (event: any) => {
                const licenceRef = doc(firestore, 'Licences', id);
                await updateDoc(licenceRef, {
                  isDemo: event.target.checked,
                });
              }}
              checked={checked}
            />
          );
        },
      },
      {
        Header: 'qns',
        accessor: 'questionnaires',
        sortType: 'boolean',
        Cell: ({ value }: any) => {
          const [id, checked] = value;
          return (
            <CheckboxCell
              onChange={async (event: any) => {
                const licenceRef = doc(firestore, 'Licences', id);
                await updateDoc(licenceRef, {
                  questionnaires: event.target.checked,
                });
              }}
              checked={checked}
            />
          );
        },
      },
      {
        Header: 'qns on 1st use',
        accessor: 'questionnairesOnByDefault',
        sortType: 'boolean',
        Cell: ({ value }: any) => {
          const [id, checked] = value;
          return (
            <CheckboxCell
              onChange={async (event: any) => {
                const licenceRef = doc(firestore, 'Licences', id);
                await updateDoc(licenceRef, {
                  questionnairesOnByDefault: event.target.checked,
                });
              }}
              checked={checked}
            />
          );
        },
      },
      {
        Header: 'n opened',
        accessor: 'appOpened',
        type: 'number',
        Cell: ({ value }: any) => {
          return <DefaultCell value={value} />;
        },
      },
      {
        Header: 'd used',
        accessor: 'daysUsed',
        type: 'number',
        Cell: ({ value }: any) => {
          return <DefaultCell value={value} />;
        },
      },
      {
        Header: 'clinician',
        accessor: 'isClinicianLicence',
        sortType: 'boolean',
        Cell: ({ value }: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              {value ? <Done fontSize="medium" color="info" /> : <></>}
            </MDBox>
          );
        },
      },
      {
        Header: 'log',
        accessor: (row: any) => ({
          id: row.id,
          isClinicianLicence: row.isClinicianLicence,
          appOpened: row.appOpened,
        }),
        Cell: ({ value }: any) => {
          return (
            value.appOpened && (
              <Link
                onClick={() => {
                  navigate(`/licences/${value.id}/events`);
                }}
                sx={{
                  cursor: 'pointer',
                  color: 'blue',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                <span>user log</span>
              </Link>
            )
          );
        },
      },
      {
        Header: 'activated',
        accessor: 'validFrom',
        type: 'datetime',
        Cell: ({ value }: any) => {
          return <DefaultCell value={value?.toLocaleDateString() || ''} />;
        },
      },
    ]);
    return {
      columns,
      rows:
        licences
          ?.filter((licence: any) => {
            if (isSuperAdmin) {
              return true;
            }
            if (licence.provider.id === portalUser?.provider.id) {
              return true;
            }
            const provider = serviceProviders.find(
              (provider) => provider.id === licence.provider.id
            );
            return provider?.resellerId === portalUser?.provider.id;
          })
          .map((licence: any) => ({
            id: licence.id,
            publicId: licence.publicId,
            provider: serviceProviders.find(
              (provider) => provider.id === licence.provider.id
            )?.name,
            created: licence.created?.toDate() || null,
            licenceCode: licence.licenceCode,
            plan: licence.plan,
            description: licence.description,
            isDemo: [licence.id, licence.isDemo],
            questionnaires: [licence.id, licence.questionnaires],
            appOpened: licence.stats?.app_opened || '',
            daysUsed: licence.stats?.days_used || '',
            statsUpdated: licence.stats?.updated || null,
            questionnairesOnByDefault: [
              licence.id,
              !!licence.questionnairesOnByDefault,
            ],
            isClinicianLicence: licence.isClinicianLicence,
            validFrom: licence.validFrom?.toDate() || null,
          })) || [],
      // rows: [
      //   {
      //     id: '#1',
      //     date: '1 Nov, 10:20 AM',
      //     customer: ['Testiasiakas', { image: 'T' }],
      //     product: 'V1',
      //   },
      // ],
    };
  }, [
    firestore,
    isReseller,
    isSuperAdmin,
    licences,
    navigate,
    portalUser?.provider.id,
    selectedProviderId,
    serviceProviders,
  ]);

  const columns = useMemo<any>(() => dataTableData.columns, [dataTableData]);
  const data = useMemo<any>(() => dataTableData.rows, [dataTableData]);

  const providerDropdownOptions = useMemo(
    () =>
      serviceProviders?.filter(
        (provider) =>
          isSuperAdmin ||
          provider.resellerId === portalUser?.provider.id ||
          provider.id === portalUser?.provider.id
      ) as ServiceProvider[],
    [isSuperAdmin, portalUser?.provider.id, serviceProviders]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // console.log('tableInstance', tableInstance);
  const startOfToday = useMemo(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }, []);

  const rows = tableInstance.rows;
  // console.log('rows', rows);
  const licenceIds = useMemo(
    () =>
      rows
        .filter(
          (row: any) =>
            !(
              row.original.statsUpdated &&
              row.original.statsUpdated.toDate() >= startOfToday
            )
        )
        .map((row: any) => row.original.id as string),
    [rows, startOfToday]
  );

  useEffect(() => {
    if (licenceIds.length === 0) {
      return;
    }
    getlicencestats({ licenceIds })
      .then((result: any) => {
        const resultData = result.data as licenceStats;
        setLicenceStats((prevStats) => ({ ...prevStats, ...resultData }));
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [getlicencestats, licenceIds]);

  const csvData = useMemo(
    () => [
      [
        'Created',
        'License code',
        'Description',
        'Plan',
        'Valid from',
        'Valid to',
      ],
      ...(licences?.map((licence: any) => [
        licence.created?.toDate().toLocaleDateString() || '',
        licence.licenceCode,
        licence.description,
        subscriptionPlans.find((plan: any) => plan.id === licence.plan.id)
          ?.name,
        licence.validFrom?.toDate().toLocaleDateString() || '',
        licence.validTo?.toDate().toLocaleDateString() || '',
      ]) || []),
    ],
    [licences, subscriptionPlans]
  );

  const currentDate = useMemo(() => new Date(), []);

  const currentTimestampStr = useMemo(
    () => currentDate.toLocaleString(),
    [currentDate]
  );

  // we only show ServiceProvider dropdown to superadmin

  const selectedProvider = useMemo(
    () =>
      (serviceProviders?.find(
        (provider) => provider.id === selectedProviderId
      ) || null) as ServiceProvider | null,
    [selectedProviderId, serviceProviders]
  );

  const providerName = useMemo(
    () =>
      serviceProviders?.find((provider) => provider.id === selectedProviderId)
        ?.name,
    [selectedProviderId, serviceProviders]
  );

  const providerMaxLicences = useMemo(
    () =>
      serviceProviders?.find((provider) => provider.id === selectedProviderId)
        ?.maxLicences || 0,
    [selectedProviderId, serviceProviders]
  );

  const onChangeProvider = useCallback(
    (event: any, value: ServiceProvider | null) => {
      setLicences([]);
      setLicenceStatus('loading');
      setSelectedProviderId(value?.id || null);
    },
    []
  );

  if (
    !portalUser ||
    subscriptionPlansStatus === 'loading' ||
    serviceProviderStatus === 'loading' ||
    licences === undefined
  ) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }

  // const renderMenu = (
  //   <Menu
  //     anchorEl={menu}
  //     anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
  //     transformOrigin={{ vertical: 'top', horizontal: 'left' }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //     keepMounted
  //   >
  //     <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
  //     <Divider sx={{ margin: '0.5rem 0' }} />
  //     <MenuItem onClick={closeMenu}>
  //       <MDTypography variant="button" color="error" fontWeight="regular">
  //         Remove Filter
  //       </MDTypography>
  //     </MenuItem>
  //   </Menu>
  // );

  // console.log(providerDropdownOptions);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <CSVLink
            data={csvData}
            filename={`licenses-${currentTimestampStr}.csv`}
          >
            <MDButton variant="gradient" color="info">
              export
            </MDButton>
          </CSVLink>

          <MDBox display="flex">
            {(isSuperAdmin || isReseller) &&
              serviceProviders &&
              serviceProviders.length > 0 && (
                <Autocomplete
                  blurOnSelect
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  sx={{ minWidth: 350 }}
                  options={providerDropdownOptions}
                  getOptionKey={(option: ServiceProvider) => option.id}
                  getOptionLabel={(option: ServiceProvider) =>
                    option.name + (option.trialProvider ? ' (trial)' : '')
                  }
                  value={selectedProvider || undefined}
                  renderInput={(params) => {
                    return (
                      <MDInput
                        variant="outlined"
                        {...params}
                        label="Service Provider"
                        placeholder="Select Service Provider"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            backgroundColor: 'white',
                          },
                        }}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          style: {
                            marginTop: '.25em',
                          },
                        }}
                      />
                    );
                  }}
                  renderOption={(props, value) => (
                    <Typography
                      {...props}
                      variant="body1"
                      sx={{
                        color: 'black',
                        fontWeight: !value.trialProvider ? 'bold' : 'normal',
                      }}
                    >
                      {value.name}
                      {value.trialProvider && value.resellerName
                        ? ` - (${value.resellerName} trial)`
                        : ''}
                      {value.trialProvider && !value.resellerName
                        ? ` - (trial)`
                        : ''}
                    </Typography>
                  )}
                  onChange={onChangeProvider}
                />
              )}

            {/* <MDButton
              variant={menu ? 'contained' : 'outlined'}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu} */}
            {/* <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        <Card>
          <CardHeader
            title={`Licences for ${providerName || 'All Providers'}`}
            action={
              providerMaxLicences ? (
                <MDTypography
                  variant="body2"
                  color="text"
                  sx={{ mr: 2 }}
                  size={1}
                >
                  {licences.length} of {providerMaxLicences} licences used
                </MDTypography>
              ) : null
            }
          />
          <CardContent>
            {licenceStatus === 'loading' ? (
              <CircularProgress />
            ) : (
              <DataTable
                key="licence-list"
                tableInstance={tableInstance}
                entriesPerPage={{
                  defaultValue: 50,
                  entries: [50, 100, 200, 500, 1000],
                }}
                canSearch
              />
            )}
          </CardContent>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LicenceList;
